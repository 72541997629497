import React from 'react';
import { Flex, Text, Image } from '@chakra-ui/core';

import theme, { colors } from '@theme';

export default ({ pet }) => {
  const { name, title, bio, imageSrc } = pet;
  return (
    <Flex direction="column" width={['100%', '50%']} px={4} my={6}>
      <Flex direction={['column', 'row']}>
        <Flex width={['100%', '50%']} justifyContent={['center', 'left']}>
          <Image src={imageSrc} style={{ height: '14rem', maxWidth: '14rem' }} />
        </Flex>
        <Flex direction="column" justify="flex-end" pt={[6, 0]}>
          <Text
            textAlign={['center', 'left']}
            fontWeight={theme.fontWeights.bold}
            fontSize="xl"
            color={colors.greenPea}
          >
            {name.toUpperCase()}
          </Text>
          <Text color={colors.boulder} fontSize="xl" textAlign={['center', 'left']}>
            {title}
          </Text>
        </Flex>
      </Flex>
      <Flex mt={4}>
        <Text
          fontSize="lg"
          lineHeight={1.6}
          dangerouslySetInnerHTML={{ __html: bio }}
        ></Text>
      </Flex>
    </Flex>
  );
};
