import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/core';
import Helmet from 'react-helmet';

import { colors } from '@theme';
import Layout from '../components/layout';

import Pet from '../components/aboutUs/pet';

const Index = () => {
  const pets = [
    {
      name: 'Goose',
      title: 'HR',
      bio: `Goose feels passionately about food, walks and making people happy. He's worked hard to build out his HR skillset and is willing to talk to anyone, especially on a walk. Goose first fell in love with personal finance when he learned that money helped him buy more cookies. To get an intro to Goose, find him <a target="_blank" style='color: ${colors.primary}; text-decoration: underline;' href="https://www.instagram.com/moregooseplease/">here</a>.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Goose.png',
      containsHTML: true,
    },
    {
      name: 'Ziggy',
      title: 'Security',
      bio: `Ziggy's run a one-man security team his entire life, often doing work pro-bono because of his sheer passion for the work. While his expertise is mostly with raccoons and squirrels, he's excited to expand his horizons and bring his extensive experience to Zeta's mission.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Ziggy.png',
    },
    {
      name: 'Dixie',
      title: 'CX',
      bio: `Dixie wants nothing more than to make you happy. Some would argue, it's baked into her retriever DNA. While Dixie left us to go make people happier in heaven, the mark she left on our team is not forgotten. Dixie had a quiet shuffle, a keen nose and a jest for life. She was a loyal employee, friend and heavy snorer. One of the reasons Dixie brought so much joy to her role at Zeta is because she knew how to have fun after the workday was over.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Dixie.png',
    },
    {
      name: 'Ruxby',
      title: 'Fraud',
      bio:
        'Ruxby was a big boy, so a natural fit for security. Although his size might make you think otherwise, he was also a very sensitive soul and was easily swayed by a good set of rules. He was a top performer on discipline and structure, although we had to navigate a few complaints from our janitor on the layers of slobber across our screens. He was also our most popular poster in the #whats-for-lunch Slack channel.',
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Ruxby.png',
    },
    {
      name: 'Markov',
      title: 'Engineering',
      bio: `Markov is a true desk junkie. While it might look like he's often sleeping on the job, he's mostly thinking deeply about how to solve complex technical problems. He's got an eye for design and an attention to detail that would make any front-end engineer jealous. And when he's not coding at his desk, he's looking for cookies from the neighborhood stores.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Markov.png',
    },
    {
      name: 'Luna',
      title: 'Security',
      bio: `Luna takes her work as part of Zeta's security team rather seriously. She's got strong stalking skills and an energy sure to beat out any fraudster. To ensure she catches anyone and everyone, Luna lives out of her van with her family in tow. That's the kind of commitment our team brings to the job!`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Luna.png',
    },
    {
      name: 'Freya',
      title: 'CX',
      bio: `A gentle soul with a passion for people, not cats. Freya (Luna's sister) is 100% focused on making you happy. She's got a big heart, a good soul and eyes that will turn your bad day into a good one. Freya openly takes bribes in the form of cookies, balls and long walks.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Freya.png',
    },
    {
      name: 'Penny',
      title: 'Ops',
      bio: `Miss Penny is a gem, willing to do the hard work to make your accounts function all day, every day. They say good things come in small packages, and Penny proves that's definitely true. While she's a couch potato, no one can get work done like Penny can on a  solid couch. Don't let her size fool you, Penny packs a punch in her work and in play.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Penny.png',
    },
    {
      name: 'David',
      title: 'Marketing',
      bio: `David is full of beans and will convince you to play, walk, or run irrespective of what you think you want. He's part salesman, part gentleman who can talk anyone into anything. His infectious energy will inspire you to master your money like no other, and he's not too shabby on the eyes while you're doing it.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/David.png',
    },
    {
      name: 'Tony',
      title: 'Marketing',
      bio: `Tony is a big clown - happy, silly and a little clumsy. He reminds us to laugh a little harder everyday and brings his big boy energy to every office party. Tony believes Zeta can solve the world's problems and even signed up for an account himself with his love-bug Lily. Said simply, he's a man of his word.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Tony.png',
    },
    {
      name: 'Lily',
      title: 'Security',
      bio: `Lily might seem a little quiet at first, but she's mostly sizing you up to ensure you'll be a happy Zeta customer. She's a great listener and an even better boating buddy. Her passion for keeping you safe and secure comes through in her work every day. Don't confuse her laziness for anything other than her trying to make you feel loved.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Lily.png',
    },
    {
      name: 'Maia',
      title: 'CX',
      bio: `We've always encouraged our teammates at Zeta to bring their full personalities to work. Maia does that in spades! She's spunky, spicy, sweet and determined. And she's here to make sure you have the best support experience with Zeta as possible. Her passion for helping people (and playing with toys) shines through everyday. Nothing is too scary for Maia so bring it on!`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Maia.png',
    },
    {
      name: 'Charlie',
      title: 'Operations',
      bio: `Charlie is the older, wiser silverback on our team. His years of experience are a wealth of knowledge as we tackles issues we haven't come across before. He's patient, calm, cool and collected under all circumstances, which comes in handy when dealing with fraudy folks or other stress-inducing situations. Charlie might best be described as the Zeta Yoda.`,
      imageSrc: 'https://d142whjg6dsdg2.cloudfront.net/team/pets/Charlie.png',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Pets of Zeta: Celebrating our furry family | Zeta</title>
        <link rel="canonical" href="https://www.askzeta.com/petsofzeta" />
        <meta
          name="description"
          content="Meet the adorable pets of Zeta! Celebrating the furry family members that bring joy to our team (iand our pockets)."
        />
        <meta
          property="og:description"
          content="Meet the adorable pets of Zeta! Celebrating the furry family members that bring joy to our team (iand our pockets)."
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta
          name="keywords"
          content="personal finance, banking, joint banking, joint account, couples finance, family finance, love and money, combining finances, sharing finances"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Zeta | Master Money as a Team" />
        <meta property="og:site_name" content="Zeta | Master Money as a Team" />
        <meta property="og:url" content="https://www.askzeta.com/petsofzeta" />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta property="fb:app_id" content="2316257168617802" />
      </Helmet>
      <Layout noGetStarted mode={'home'} newNav>
        <Flex direction="column">
          <Flex justifyContent="center" direction="column" pt={[0, 16]}>
            <Heading textAlign="center" color={colors.primary} fontSize="5xl" as="h1">
              Pets of Zeta
            </Heading>
          </Flex>

          <Flex className="aboutus">
            <Flex py={8} direction="column">
              <Flex direction="column" mb={[4, 6]} px={[4, 0]}>
                <Text pt={[6, 0]} fontSize={['md', 'lg']}>
                  Your favorite banking app isn't just built by incredible humans. In
                  fact, we have an entire pack of Zeta Pets who make our work possible
                  everyday. You can meet them below.
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            direction={['column', 'column', 'row']}
            className="team"
            flexWrap="wrap"
            my={8}
            width="100%"
          >
            {pets.map(pet => {
              return <Pet key={pet.name} pet={pet} />;
            })}
          </Flex>
        </Flex>
      </Layout>
    </>
  );
};

export default Index;
